import React from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import Button from '../button/button'
import Card from '../card/card'
import Visual from '../Visual'


// LOCALIZATION for later:
const locale="en-US"

// Flex div to center children
const Centered = ({children}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {children}
  </div>
)

/*
 *  Takes in the JSON representation of a Contentful Richtext field.
 *  Makes all links open in external tabs (target=_blank) with  rel=noopener norefferer
 *  Also replaces \n line breaks with <br />
 *  Extend this as needed.
 *  For example, you can use this to transform embedded entry links into the Gatsby Link component
 */
const ContentfulRichText = ({ richTextJSON, transform_options }) => {
  const default_options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return <a href={node.data.uri} target='_blank' rel='noopener noreferrer'>{children}</a>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields
        const mimeType = file[locale].contentType
        const mimeGroup = mimeType.split('/')[0]
  
        switch (mimeGroup) {
          case 'image':
            return <img
              title={ title ? title[locale] : null}
              alt={description ?  description[locale] : null}
              src={file[locale].url}
            />
          case 'application':
            return <a
              alt={description ?  description[locale] : null}
              href={file[locale].url}
              >{ title ? title[locale] : file[locale].details.fileName }
            </a>
          default:
            return <span style={{backgroundColor: 'red', color: 'white'}}> {mimeType} embedded asset </span>
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const entryType = node.data.target.sys.contentType.sys.contentful_id
        const fields = node.data.target.fields
        switch (entryType) {
          case 'button':
            return (
              <Button 
                text={fields.label[locale]}
                url={fields.link[locale]}
                line="line-expand"
              />
            )
          default:
            return <span>{entryType} inline embedded entry</span>
        } 
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        let entryType = ""
        if(node.data.target.sys.contentType) {
          entryType = node.data.target.sys.contentType.sys.contentful_id
        }
        const fields = localize(node.data.target.fields, locale)
        switch (entryType) {
          case 'embed':
            return (
              <div dangerouslySetInnerHTML={{__html:fields.snippet}} />
            )
          case 'button':
            return (
              <Centered>
                <Button 
                  text={fields.label}
                  url={fields.link}
                  use_border
                />
              </Centered>
            )
          case 'card':
            let interactivePayload = null;
            if(fields.interactive) {
              interactivePayload = {
                type: fields.interactive.fields.type[locale],
                data: {file: {url: fields.interactive.fields.data[locale].fields.file[locale].url}}
              }
            }
            return (
              <Card
                backgroundColor={fields.color}
                reverse={fields.order && fields.order%2 === 0 ? true : false}
                cardStyle={fields.style === fields.style && fields.style !== null ? fields.style : ''}
                flip={fields.flip}
                flipTitle={fields.flipTitle}
                flipJSON={fields.flipContent ? fields.flipContent : null}
                styleOverride={fields.styleOverride}
              >
                <div style={{margin:`0 auto`}}>
                  <div style={{padding:`15px 15px 0 15px`}}>
                    {!fields.hideOrder && <h1>{fields.order}</h1>}
                    <h2>{fields.title}</h2>
                    <ContentfulRichText richTextJSON={fields.text}/>
                  </div>
                </div>
                {
                  (fields.interactive || fields.image) ? <Visual imageTitle={fields.imageTitle} imageSrc={fields.image && fields.image.fields.file[locale].url} interactive={interactivePayload && interactivePayload} /> : null
                }
              </Card>
            )
          default:
            return <span>{entryType} block embedded entry</span>
        } 
      }
    },
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  }

  transform_options = transform_options && typeof transform_options !== 'undefined' ? merge(default_options, transform_options) : default_options;
  return documentToReactComponents(richTextJSON, transform_options)
}


const localize = (fields, locale) => {
  let result = {}
  for(var key in fields) {
    result[key] = fields[key][locale]
  }
  return result
}

// overwrite values of old_obj with values of new_obj, and appends new_obj values if not present in old_obj
const merge = (old_obj, new_obj) => {
  for (let [key, value] of Object.entries(new_obj)) {
    if(typeof key !== 'undefined' && typeof old_obj !== 'undefined' && key in old_obj) {
      // if both values are objects, merge again
      if('object' === typeof old_obj[key] && 'object' === typeof value) {
        old_obj[key] = merge(old_obj[key], value)
      }
      // otherwise, overwrite the old value with the new value
      else {
        old_obj[key] = value
      }
    }
  }
  return old_obj
}

export default ContentfulRichText;
