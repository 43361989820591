import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withSize } from 'react-sizeme'
import './card.css';
import ContentfulRichText from '../contentful/ContentfulRichText';

const ColorLuminance = (hex, lum) => {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}

const GetStyle = (cardStyle, backgroundColor, shadow, reverse, minHeight, minWidth) => {
  backgroundColor = backgroundColor ? backgroundColor : '#9CDECF';
  var shadowStyle = shadow ? `-1.8px 20px 40px 0 rgba(0, 0, 0, 0.1)` : ``;
  var backgroundStyle = '';
  minHeight = '500px';
  switch (cardStyle) {
    case 'slash':
      backgroundStyle = reverse ? `linear-gradient(55deg, ${ColorLuminance(backgroundColor, 0.1)} 50%, ${backgroundColor} 50%)` : `linear-gradient(-55deg, ${ColorLuminance(backgroundColor, 0.1)} 50%, ${backgroundColor} 50%)`;
      break;
    case 'slash-clear':
      backgroundStyle = reverse ? `linear-gradient(15deg, ${ColorLuminance(backgroundColor, 0.5)}, ${ColorLuminance(backgroundColor, 0.7)})` : `linear-gradient(-15deg, ${ColorLuminance(backgroundColor, 0.7)}, ${ColorLuminance(backgroundColor, 0.5)})`;
      //backgroundStyle = reverse ? `linear-gradient(50deg, ${ColorLuminance(backgroundColor, 0.7)} 80%, ${backgroundColor})` : `linear-gradient(-50deg, ${ColorLuminance(backgroundColor, 0.7)} 80%, ${backgroundColor})`;
      break;
    case 'smooth-linear':
      backgroundStyle = reverse ? `linear-gradient(15deg, ${ColorLuminance(backgroundColor, 0.5)}, ${backgroundColor})` : `linear-gradient(-15deg, ${ColorLuminance(backgroundColor, 0.5)}, ${backgroundColor})`;
      break;
    case 'no-card':
      backgroundStyle = 'transparent';
      shadowStyle = 'none';
      minHeight = null;
      break;
    default:
      backgroundStyle = '#9CDECF';
  }
  return {
    background: backgroundStyle,
    //margin: `2rem 0 2rem 0`,
    padding: `1rem`,
    display: `flex`,
    flexWrap: `wrap`,
    flex: `auto`,
    flexDirection: reverse ? 'row-reverse' : 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    boxShadow: shadowStyle,
    minHeight: minHeight,
    minWidth: minWidth,
    width: '100%',
  }
}

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardClass: 'card',
    }
  }

  flipCard() {
    this.setState(prevState => {
      var className = prevState.cardClass === 'card' ? 'card-flip' : 'card';
      if(className !== prevState.cardClass) {
        return { ...prevState, cardClass:className }
      }
    })
  }

  render() {
    const { backgroundColor, children, shadow, reverse, cardStyle, flip, flipTitle, flipJSON, styleOverride } = this.props
    const { width, height } = this.props.size
    const sections = children.filter((child) => child !== null);
    const customStyles = styleOverride ? JSON.parse(styleOverride.internal.content) : {};
    //const hrefRegex = new RegExp('<a href', 'g');
    return (
      <div className={this.state.cardClass} style={{
        margin: `0 0 3rem 0`,
        display: `flex`,
        flexWrap: `wrap`,
        flex: `auto`,
        flexDirection: reverse ? 'row-reverse' : 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: `95%`,
        maxWidth: `1300px`,
        minWidth: `310px`,
        position: 'relative',
      }}>
        <div className='content' style={{height:'100%', width: '100%', position:'relative',  ...customStyles}}>
          <div className='front' style={{...GetStyle(cardStyle, backgroundColor, shadow, reverse, height, width)}}>
            {sections.map((child, index) => 
              <div key={index} style={{maxWidth: `100%`, width: width/(sections.length+.1) > 400 ? width/(sections.length+.1) : width}}>
                {child}
              </div>
            )}
            {
              flip ?
              <div className="flipper" onClick={this.flipCard.bind(this)}><h2 style={{margin:0, paddingBottom: '0.2rem'}}>?</h2></div> :
              null
            }
          </div>
          { flip ?
            <div className='back' style={{...GetStyle(cardStyle, backgroundColor, shadow, !reverse, height, width), alignItems:'auto'}}>
              <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                <h2 style={{marginBottom:'2.4rem'}}>{flipTitle}</h2>
                <ContentfulRichText richTextJSON={flipJSON}/>
                  {
                    flip ?
                    <div className="flipper" onClick={this.flipCard.bind(this)}><h2 style={{margin:0, padding: '0 0 0.3rem 0.15rem'}}></h2></div> :
                    null
                  }
              </div>
            </div>
            : <div />
          } 
        </div>
      </div>
    )
  }
}

Card.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  shadow: PropTypes.bool,
  reverse: PropTypes.bool,
}

Card.defaultProps = {
  backgroundColor: `#9CDECF`,
  shadow: true,
  reverse: false,
}

export default withSize({ monitorHeight: true })(Card)
