import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { scaleOrdinal } from 'd3-scale'
import { select } from 'd3-selection'
import createGraph from 'ngraph.graph'
import centrality from 'ngraph.centrality'
import { forceSimulation, forceLink, forceManyBody, forceCenter, forceX, forceY } from 'd3-force'
import { drag } from 'd3-drag'
import {event as currentEvent} from 'd3';



class Network extends Component {
  constructor(props) {
    super(props)
    this.createNetwork = this.createNetwork.bind(this)
    this.state = {data: this.createNetwork()}
  }

  createNetwork() {
    const group_members = this.props.data.group_members;
    const lower_bound = this.props.data.lower_bound;
    const person_lower_bound = this.props.data.person_lower_bound;
    var centrality_measures = {};
    const get_nodes = (members, person_lower_bound) => {
      const nodes = []
      Object.keys(members).forEach(d => {
        const attendees = members[d];
        if(person_lower_bound) {
          var filtered = {}
          for(var person in attendees) {
            if(attendees[person] >= person_lower_bound) {
             filtered[person] = attendees[person] 
            }
          }
          if(Object.keys(filtered).length > 0) {
             nodes.push({id: d, group: 0});
          }
        }
        else {
          nodes.push({id: d, group: 0});
        }
      });
      return nodes;
    }

    const filter_func = (d, lower_bound) => {
      return lower_bound ? d.value >= lower_bound : 1
    }

    var nodes = get_nodes(group_members, person_lower_bound).map(d => Object.create(d)).sort((a,b) => a.id > b.id ? -1 : 1);
    const node_map = {}
    nodes.forEach(d => node_map[d.id] = true);

    const links = this.props.data.links.filter(d => filter_func(d, lower_bound)).map(d => Object.create(d)).filter(d => (d.source in node_map && d.target in node_map));

    var g = createGraph();
    links.forEach(d => {g.addLink(d.source, d.target)});
    centrality_measures['betweenness'] = centrality.betweenness(g);
    centrality_measures['degree'] = centrality.degree(g);
    const {betweenness, degree} = {...centrality_measures}

    nodes = nodes.map(d => {
      return {id:d.id, centrality:{degree:degree[d.id], betweenness:betweenness[d.id]}, size:Object.keys(group_members[d.id]).length}
    })
    return {nodes, links}
  }

  render() {
    return(
      <Graph index={this.props.index} data={this.state.data} height={this.props.height ? this.props.height : this.props.width} width={this.props.width}/>
    )
  }
}

///////////////////////////////////////////////////////////
/////// Functions and variables

const color_list = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', "#9CDECF", "#f2617a", "#3b3836", "#ffc730"]

const color = scaleOrdinal().range(color_list);


const enterNode = (selection) => {
    selection.select('circle')
        .attr("r", d => Math.sqrt(d.size))
        .style("fill", function(d) { return color(d.id) })
};

const updateNode = (selection) => {
    selection.attr("transform", (d) => "translate(" + d.x + "," + d.y + ")")

};

const enterLink = (selection) => {
    selection.attr("stroke-width", 2)
    .attr("stroke", "#999")
    .attr("stroke-opacity", 0.7)
    .selectAll("line")
    .join("line")
    .attr("stroke-width", d => Math.sqrt(d.value))
};

const updateLink = (selection) => {
    selection
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);
};

const updateGraph = (selection) => {
    selection.selectAll('.node')
        .call(updateNode)
    selection.selectAll('.link')
        .call(updateLink);
};


///////////////////////////////////////////////////////////
/////// Graph component. Holds Link and Node components

class Graph extends React.Component {

  componentDidMount() {
    this.d3Graph = select(ReactDOM.findDOMNode(this));
    
    var force = forceSimulation(this.props.data.nodes)
      .force("link", forceLink(this.props.data.links).id(d => d.id))
      .force("charge", forceManyBody())
      .force("center", forceCenter(this.props.width/2, this.props.width/2))
      .force("x", forceX())
      .force("y", forceY())

    function dragStarted(d) {
        if (!currentEvent.active) force.alphaTarget(0.3).restart()
        d.fx = d.x
        d.fy = d.y

    }

    function dragging(d) {
        d.fx = currentEvent.x
        d.fy = currentEvent.y
    }

    function dragEnded(d) {
        if (!currentEvent.active) force.alphaTarget(0)
        d.fx = null
        d.fy = null
    }

    select(`#svg-${this.props.index}`).selectAll('g.node')
      .call(drag()
        .on("start", dragStarted)
        .on("drag", dragging)
        .on("end", dragEnded)
      )
      .on('mouseover', d => mouseover(d))
      .on('mouseout', d => mouseout());
    
      force.on('tick', () => {
          this.d3Graph.call(updateGraph)
      });

      const show_centrality = (d) =>  {
        select(`#svg-${this.props.index}`).selectAll(`#text`).append("text")
        .attr("x", 0)
        .attr("y", 50)
        .text(d.centrality.degree && d.centrality.betweenness ? `Degree: ${d.centrality.degree} | Betweenness: ${d.centrality.betweenness.toPrecision(3)}`: '')
        .attr("font-size", "20px")
        .attr("class", "centrality");
      };
      
      const show_name = (d) => {
        select(`#svg-${this.props.index}`).select(`#text`).append("text")
          .attr("x", 0)
          .attr("y", 25)
          .text(`${d.id} | ${d.size} members`)
          .attr("font-size", "20px")
          .attr("class", "name");
      }
              
      const remove_text = () => {
        select(`#svg-${this.props.index}`).select(`#text`).selectAll("text").remove();
      }
        
      const mouseover = (d) => {
        show_name(d);
        show_centrality(d);
      };
      
      const mouseout = () => remove_text();
  }

  render() {
      var nodes = this.props.data.nodes.map( (node) => {
          return (
          <Node
              data={node}
              name={node.name}
              key={node.id}
          />);
      });
      var links = this.props.data.links.map( (link,i) => {
          return (
              <Link
                  key={link.target+i}
                  data={link}
              />);
      });
      return (
          <svg id={"svg-" + this.props.index} className="graph" width={this.props.width} height={this.props.height}>
              <g id="text"></g>
              <g>
                  {links}
              </g>
              <g>
                  {nodes}
              </g>
          </svg>
      );
  }
}

///////////////////////////////////////////////////////////
/////// Link component

class Link extends React.Component {

  componentDidMount() {
      this.d3Link = select(ReactDOM.findDOMNode(this))
          .datum(this.props.data)
          .call(enterLink);
  }

  componentDidUpdate() {
      this.d3Link.datum(this.props.data)
          .call(updateLink);
  }

  render() {
      return (
              <line className='link' />
      );
  }
}

///////////////////////////////////////////////////////////
/////// Node component

class Node extends React.Component {

  componentDidMount() {
      this.d3Node = select(ReactDOM.findDOMNode(this))
          .datum(this.props.data)
          .call(enterNode)
  }

  componentDidUpdate() {
      this.d3Node.datum(this.props.data)
          .call(updateNode)
  }

  handle(e){
      console.log(this.props.data.id + ' been clicked')
  }

  render() {
      return (
          <g className='node'>
              <circle ref="dragMe" onClick={this.handle.bind(this)}/>
          </g>
      );
  }
}

export default Network