import React from 'react'
import Plot from 'react-plotly.js'
import './plotly.css'

const Plotly = ({ data }) => (
  <Plot
    style={{width:'100%', height:'100%'}}
    data={data.data}
    layout={{...data.layout, autosize:true, automargin:true}}
    config={data.config ? data.config : {scrollZoom: false}}
    displaymodebar={false}
    useResizeHandler={true}
    scrollZoom={false}
  />
)

export default Plotly