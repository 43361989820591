import React from 'react'
import Img from 'gatsby-image';
import Interactive from '../components/interactives/interactive'

const Visual = ({imageTitle, interactive, image, imageSrc, index}) => {
    return(
      <div>
        {
          imageTitle && <div style={{display:'flex', justifyContent:'center'}}><span style={{fontWeight:'bold'}}>{imageTitle}</span></div>
        }
        {
          interactive
          ? <Interactive type={interactive.type} data_url={interactive.data.file.url} index={index}/>
          : (image ? <Img fluid={image.fluid} /> : <img src={imageSrc} alt={imageTitle} />)
        }
      </div>
    )
  }

  export default Visual