import React, { Component } from 'react'
import Network from './network'
import Plotly from './plotly'
import { withSize } from 'react-sizeme'
import './interactive.css'


class Interactive extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      selection: null,
    }
    this.handleSelection = this.handleSelection.bind(this)
  }

  fetch_file = async (url) => {
    let req = await fetch(url);
    let result = await req.json();
    if(result.selection) {
      this.setState(prevState => ({
        ...prevState,
        data: result.all_data[result.selection.default],
        options: Object.keys(result.all_data),
        all_data: result.all_data,
        selection: result.selection.default,
      }))
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        data:result,
      }))
    }
    
  }

  handleSelection(event) {
    this.setState({
      ...this.state,
      data: this.state.all_data[event.target.value],
      selection: event.target.value,
    })
  }

  componentDidMount() {
    this.fetch_file(this.props.data_url)
  }

  render() {
    const type = this.props.type;
    const { width, height } = this.props.size
    var graph;
    if(this.state.data) {
      if(type === 'network') {
        graph = <Network data={this.state.data} height={height} width={width} index={this.props.index}/>
      }
      else {
        graph = <Plotly data={this.state.data} index={this.props.index}/>
      }
      if(this.state.selection) {
        return <div>
          {graph}
          <div>
            <select className='select' value={this.state.selection} onChange={this.handleSelection.bind(this)}>
              {this.state.options.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>
      }
      return graph;
    }
      
    return <div />
  }
  
}

export default withSize({ monitorHeight: true })(Interactive)